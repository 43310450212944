var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      dense: "",
      outlined: "",
      "return-object": "",
      "item-text": "name",
      items: _vm.fundingAgencies,
      disabled: _vm.disabled,
      label: _vm.message,
      "prepend-inner-icon": "business",
      "no-data-text": "Nenhuma agência de fomento"
    },
    model: {
      value: _vm.agency,
      callback: function($$v) {
        _vm.agency = $$v
      },
      expression: "agency"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }