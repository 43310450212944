var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("h5", { staticClass: "title" }, [
                        _vm._v("Trancamentos")
                      ]),
                      _c("v-divider")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_c("h4", [_vm._v(_vm._s(_vm.item.student))])]
                  )
                ],
                1
              ),
              _vm.item.courseBreaks.length > 0
                ? _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    _vm._l(_vm.item.courseBreaks, function(b) {
                      return _c(
                        "v-col",
                        {
                          key: b.id,
                          staticClass: "text-left",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("div", [_vm._v("Status: " + _vm._s(b.status))]),
                          _c("div", [_vm._v(_vm._s(b.description))]),
                          _c("div", [
                            _vm._v("Início: " + _vm._s(_vm.fDate(b.startDate)))
                          ]),
                          _c("div", [
                            _vm._v("Término: " + _vm._s(_vm.fDate(b.endDate)))
                          ]),
                          _c("v-divider")
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("div", [
                            _vm._v("Aluno não possui trancamentos registrados.")
                          ])
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "green" },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }