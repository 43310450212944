<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center"><h4>Excluir a bolsa de estudos?</h4></v-card-title>
      <v-card-text class="text-center">
        <p>
          <strong>Atenção!</strong> Esse procedimento não poderá ser desfeito. A bolsa será removida do banco de dados e não poderá ser recuperada!
        </p>
        <p>Tem certeza que deseja excluir permanentemente a bolsa de estudos do aluno <strong>{{ scholarship.student }}</strong>?</p>
      </v-card-text>
      <v-card-actions>
        <v-row>
            <v-col cols="12" class="text-center">
                <v-progress-linear indeterminate color="green" v-show="disabled"></v-progress-linear>
            </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="green"  :disabled="disabled" @click="asyncDelScholarship()">Excluir</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="red"  :disabled="disabled" @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ScholarshipDeactivate",
  props: ["scholarship"],
  data() {
    return {
        disabled: false
    };
  },

  created() {},

  methods: {
    ...mapActions("scholarship", ["ActionDelScholarship"]),

    async asyncDelScholarship(){
        try{
            this.disabled = true;
            await this.ActionDelScholarship(this.scholarship.id);
            this.closeDialog();
        } catch(e){
            console.error('Erro ao remover a bolsa do aluno ' + this.scholarship.student, e);
        }finally {
            this.disabled = false;
        }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>