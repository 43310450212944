<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row wrap>
          <v-col cols="12" class="text-center">
            <h5 class="title">Bolsa de Estudos</h5>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="text-center">
            <h5>{{ item.student }}</h5>
          </v-col>
          <v-col cols="12" class="text-left">
            <h6>curso: {{ item.course }}</h6>
            <h6>período: {{ item.begin }} - {{ item.end }}</h6>
            <h6>ativa: {{ item.active ? "SIM" : "NÃO" }}</h6>
            <h6>cota: {{ item.quota }}</h6>
            <h6>valor: R$ {{ item.value }}</h6>
            <h6>agência: {{ item.agency }}</h6>
          </v-col>
          <v-col cols="12" class="text-left">
            <v-textarea outlined readonly disabled label="Observações">{{
              item.annotation
            }}</v-textarea>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12" class="text-center">
            <v-btn outlined color="green" @click="closeDialog()">OK</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ScholarshipInfo",
  props: ["item"],

  data() {
    return {};
  },

  created() {},

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>