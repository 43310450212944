var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-left", attrs: { cols: "6" } }, [
                _c("h3", [_vm._v("Bolsas de Estudos")])
              ]),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        "x-small": "",
                        color: "green",
                        disabled: _vm.agencies.length > 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.openInsertDialog()
                        }
                      }
                    },
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "mt-5": "" } },
        [
          _c(
            "v-card",
            { attrs: { color: "grey lighten-4" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md3: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "append-icon": "search",
                          placeholder: "Buscar aluno"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  items: _vm.items,
                  search: _vm.search,
                  headers: _vm.headers,
                  loading: _vm.loading,
                  "items-per-page": 25,
                  "loading-text": "Buscando bolsas de estudo",
                  "no-data-text": "Não foram encontradas bolsas de estudo"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.agency))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.quota))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.value))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.course))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(props.item.student))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(_vm.dmy(props.item.begin)))
                          ]),
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(_vm.dmy(props.item.end)))
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    outlined: "",
                                    color: "black",
                                    disabled:
                                      props.item.courseBreaks.length == 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openCourseBreaks(props.item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(props.item.courseBreaks.length))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    color: "black"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openInfoDialog(props.item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("visibility")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    color: "green",
                                    disabled: !props.item.active
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDeactiveDialog(props.item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("flag")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    outlined: "",
                                    color: "orange",
                                    disabled: !props.item.active
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openUpdateDialog(props.item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("edit")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    outlined: "",
                                    color: "red"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDelDialog(props.item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("delete")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.insertDialog,
            callback: function($$v) {
              _vm.insertDialog = $$v
            },
            expression: "insertDialog"
          }
        },
        [
          _c("ScholarshipInsert", {
            attrs: { scholarship: _vm.item },
            on: {
              "close-dialog": function($event) {
                return _vm.closeInsertDialog()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.infoCourseBreaks,
            callback: function($$v) {
              _vm.infoCourseBreaks = $$v
            },
            expression: "infoCourseBreaks"
          }
        },
        [
          _c("ScholarshipBreaksInfo", {
            attrs: { item: _vm.breaks },
            on: {
              "close-dialog": function($event) {
                return _vm.closeCourseBreaks()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.deactivateDialog,
            callback: function($$v) {
              _vm.deactivateDialog = $$v
            },
            expression: "deactivateDialog"
          }
        },
        [
          _c("ScholarshipDeactivate", {
            attrs: { scholarship: _vm.item },
            on: {
              "close-dialog": function($event) {
                return _vm.closeDeactiveDialog()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.delDialog,
            callback: function($$v) {
              _vm.delDialog = $$v
            },
            expression: "delDialog"
          }
        },
        [
          _c("ScholarshipDelete", {
            attrs: { scholarship: _vm.item },
            on: {
              "close-dialog": function($event) {
                return _vm.closeDelDialog()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.updateDialog,
            callback: function($$v) {
              _vm.updateDialog = $$v
            },
            expression: "updateDialog"
          }
        },
        [
          _c("ScholarshipUpdate", {
            attrs: { item: _vm.item },
            on: {
              "close-dialog": function($event) {
                return _vm.closeUpdateDialog()
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.infoDialog,
            callback: function($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog"
          }
        },
        [
          _c("ScholarshipInfo", {
            attrs: { item: _vm.item },
            on: {
              "close-dialog": function($event) {
                return _vm.closeInfoDialog()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }