var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "4" } },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "return-object": "",
                  "item-text": "id",
                  "item-value": "id",
                  items: _vm.quotas,
                  disabled: _vm.disabled,
                  label: _vm.message,
                  "prepend-inner-icon": "school",
                  "no-data-text": "Cotas inexistentes"
                },
                model: {
                  value: _vm.quota,
                  callback: function($$v) {
                    _vm.quota = $$v
                  },
                  expression: "quota"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  label: "Valor R$",
                  readonly: "",
                  disabled: "",
                  "prepend-inner-icon": "school"
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  label: "Curso",
                  readonly: "",
                  disabled: "",
                  "prepend-inner-icon": "school"
                },
                model: {
                  value: _vm.courseName,
                  callback: function($$v) {
                    _vm.courseName = $$v
                  },
                  expression: "courseName"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }