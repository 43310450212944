<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center"><h4>Desativar a bolsa?</h4></v-card-title>
      <v-card-text class="text-center">
        <p>
          <strong>Atenção!</strong> Esse procedimento não poderá ser desfeito.
        </p>
        <p>Tem certeza que deseja desativar a bolsa do aluno <strong>{{ scholarship.student }}</strong>?</p>
      </v-card-text>
      <v-card-actions>
        <v-row>
            <v-col cols="12" class="text-center">
                <v-progress-linear indeterminate color="green" v-show="disabled"></v-progress-linear>
            </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="green"  :disabled="disabled" @click="asynDeactivateScholarship()">Desativar</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="red"  :disabled="disabled" @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ScholarshipDeactivate",
  props: ["scholarship"],
  data() {
    return {
        disabled: false
    };
  },

  created() {},

  methods: {
    ...mapActions("scholarship", ["ActionDeactivateScholarship"]),

    async asynDeactivateScholarship(){
        try{
            this.disabled = true;
            await this.ActionDeactivateScholarship(this.scholarship.id);
            this.closeDialog();
        } catch(e){
            console.error('Erro ao desativar a bolsa do aluno ' + this.scholarship.student, e);
        }finally {
            this.disabled = false;
        }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>