var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      attrs: { fluid: "" },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeDialog()
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "justify-center" }, [
            _c("h4", [_vm._v("Excluir a bolsa de estudos?")])
          ]),
          _c("v-card-text", { staticClass: "text-center" }, [
            _c("p", [
              _c("strong", [_vm._v("Atenção!")]),
              _vm._v(
                " Esse procedimento não poderá ser desfeito. A bolsa será removida do banco de dados e não poderá ser recuperada! "
              )
            ]),
            _c("p", [
              _vm._v(
                "Tem certeza que deseja excluir permanentemente a bolsa de estudos do aluno "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.scholarship.student))]),
              _vm._v("?")
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-linear", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.disabled,
                            expression: "disabled"
                          }
                        ],
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "green",
                            disabled: _vm.disabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.asyncDelScholarship()
                            }
                          }
                        },
                        [_vm._v("Excluir")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "red",
                            disabled: _vm.disabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }