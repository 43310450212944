<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center"
        ><h4>Inclusão de nova bolsa de estudos</h4></v-card-title
      >
      <v-card-text class="text-center">
        <v-row class="justify-center">
          <v-col cols="9" class="text-center">
            <v-select
              :autofocus="true"
              outlined
              dense
              label="Aluno"
              prepend-inner-icon="school"
              no-data-text="Não foram listados os alunos"
              v-model="academicLife"
              :disabled="disabled"
              :items="academicLifes"
              item-text="student"
              item-value="id"
              return-object
            >
              Alunos</v-select
            >
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              readonly
              disabled
              dense
              prepend-inner-icon="school"
              label="Curso"
              v-model="academicLife.course"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-select
              outlined
              dense
              prepend-inner-icon="business"
              no-data-text="Não foram encontradas agências"
              v-model="agency"
              :items="fundingAgencies"
              :item-text="'name'"
              :item-value="'id'"
              label="Agência de Fomento"
              :disabled="disabled"
              return-object
            >
              Agência</v-select
            >
          </v-col>
          <v-col cols="4" class="text-center">
            <v-select
              outlined
              dense
              prepend-inner-icon="school"
              v-model="quota"
              :items="quotas"
              item-text="id"
              item-value="id"
              return-object
              label="Cota"
              no-data-text="Não foram encontradas cotas correspondentes!"
              :disabled="disabled"
              >Cota</v-select
            >
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="attach_money"
              readonly
              disabled
              label="Valor R$"
              v-model="quota.value"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Curso"
              readonly
              disabled
              prepend-inner-icon="school"
              v-model="quota.course"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="text-center">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="beginDate"
                  label="Data de início"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="postAsyncAvailableResources()"
                  :disabled="disabled"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="beginDate"
                @input="menu1 = false"
                locale="pt-br"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" class="text-center">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="endDate"
                  label="Data de término"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="postAsyncAvailableResources()"
                  :disabled="disableEndDate"
                  :autofocus="endDateFocus"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="menu2 = false"
                locale="br"
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="text-center ma-0 pa-2"
            ><v-checkbox v-model="check" color="grey"></v-checkbox
          ></v-col>
          <v-col cols="12" class="text-center">
            <v-textarea
              label="Observação"
              placeholder="Digite uma observação..."
              v-model="annotation"
              outlined
              @keypress.enter="asyncPostScholarship()"
              :rules="[rules.length]"
              counter="2000"
              :disabled="disabled"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-linear
              indeterminate
              color="green"
              v-show="disabled"
            ></v-progress-linear>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="red"
              :disabled="disabled"
              @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="green"
              :disabled="disabled"
              @click="asyncPostScholarship()"
              >Adicionar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatText } from "@/utils/formatField";

export default {
  name: "ScholarshipInsert",
  data() {
    return {
      beginDate: new Date().toISOString().substr(0, 7),
      endDate: null,
      disableEndDate: true,
      endDateFocus: false,
      check: false,
      value: 0,
      menu: false,
      menu1: false,
      menu2: false,
      modal: false,
      agency: {},
      quota: {},
      academicLife: {},
      quotas: [],
      disabled: false,
      annotation: null,
      rules: {
        length: (v) =>
          formatText.minor2000(v) ||
          "A observação não deve exceder 2000 caracteres!",
      },
    };
  },

  created() {
    this.endDate = null;
    if (this.fundingAgencies.length == 0) this.getAsyncFundingAgencies();
    if (this.academicLifes.length == 0) {
      this.getAscynAcademicLifes();
    }
  },

  computed: {
    ...mapState("academicLife", ["academicLifes"]),
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("scholarship", ["ActionPostScholarship"]),
    ...mapActions("academicLife", ["ActionGetAcademicLifes"]),
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),
    ...mapActions("scholarshipQuota", [
      "ActionGetScholarshipQuotaByFundingAgency",
    ]),

    async asyncPostScholarship() {
     /* console.log(this.configData(this.beginDate, true));
      console.log(this.configData(this.endDate, false));*/

      try {
        this.disabled = true;
        if (this.beginDate < this.endDate || this.endDate == null) {
          let body = {
            begin: this.configData(this.beginDate, true),
            end: this.configData(this.endDate, false) || null,
            academicLifeId: this.academicLife.id,
            scholarshipQuotaId: this.quota.id,
            annotation: this.annotation,
          };
          await this.ActionPostScholarship(body);
          this.closeDialog();
        }
      } catch (e) {
        console.error("Erro ao inserir nova bolsa do aluno");
      } finally {
        this.disabled = false;
      }
    },

    async getAsyncFundingAgencies() {
      try {
        this.disabled = true;
        await this.ActionGetFundingAgencies();
        this.disabled = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    async getAsyncScholarshipQuotaByFundingAgency(id) {
      try {
        this.disabled = true;
        const res = await this.ActionGetScholarshipQuotaByFundingAgency(id);
        this.quotas = res.body.filter(
          (e) =>
            e.active === true &&
            e.allocated === false &&
            e.courseCode === this.academicLife.courseCode
        );
        this.disabled = false;
        return res;
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    async getAscynAcademicLifes() {
      try {
        this.disabled = true;
        await this.ActionGetAcademicLifes();
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    configData(date, isBeginDate) {
      let d = new Date(date);
      let dataInicio = new Date(d.valueOf() - d.getTimezoneOffset() * 60000);
      let dataFim = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);

      if (isBeginDate)
        return new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 1);
      else {
        if (date == null) return null;
        else return new Date(dataFim.getFullYear(), dataFim.getMonth() + 1, 0);
      }
    },

    closeDialog() {
      this.academicLife = {};
      this.quota = {};
      this.quotas = [];
      this.agency = null;
      this.annotation = null;
      this.$emit("close-dialog");
    },
  },

  watch: {
    academicLife() {
      this.agency = null;
      this.quotas = [];
      this.quota = { id: null, value: null, courseCode: null };
    },

    check(newValue) {
      this.disableEndDate = !newValue;
      if (this.disableEndDate) {
        this.endDate = null;
        this.endDateFocus = false;
      } else {
        this.endDateFocus = true;
        this.endDate = new Date().toISOString().substr(0, 7);
      }
    },

    async agency(newValue) {
      if (newValue) {
        this.quotas = [];
        this.quota = { id: null, value: null, courseCode: null };
        const res = await this.getAsyncScholarshipQuotaByFundingAgency(
          newValue.id
        );
        this.quotas = res.body.filter(
          (e) => e.courseCode === this.academicLife.courseCode && !e.allocated
        );
      }
    },
  },
};
</script>

<style>
</style>