var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("h5", { staticClass: "title" }, [
                        _vm._v("Bolsa de Estudos")
                      ]),
                      _c("v-divider")
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_c("h5", [_vm._v(_vm._s(_vm.item.student))])]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "12" } },
                    [
                      _c("h6", [_vm._v("curso: " + _vm._s(_vm.item.course))]),
                      _c("h6", [
                        _vm._v(
                          "período: " +
                            _vm._s(_vm.item.begin) +
                            " - " +
                            _vm._s(_vm.item.end)
                        )
                      ]),
                      _c("h6", [
                        _vm._v(
                          "ativa: " + _vm._s(_vm.item.active ? "SIM" : "NÃO")
                        )
                      ]),
                      _c("h6", [_vm._v("cota: " + _vm._s(_vm.item.quota))]),
                      _c("h6", [_vm._v("valor: R$ " + _vm._s(_vm.item.value))]),
                      _c("h6", [_vm._v("agência: " + _vm._s(_vm.item.agency))])
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-textarea",
                        {
                          attrs: {
                            outlined: "",
                            readonly: "",
                            disabled: "",
                            label: "Observações"
                          }
                        },
                        [_vm._v(_vm._s(_vm.item.annotation))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "green" },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-actions")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }