<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center"
        ><h4>Atualizar bolsa de estudos</h4>
      </v-card-title>
      <v-card-text class="text-center">
        <v-row class="justify-center">
          <v-col cols="9" class="text-center">
            <v-text-field
              dense
              disabled
              outlined
              readonly
              v-model="scholarship.studentName"
              prepend-inner-icon="school"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              dense
              disabled
              outlined
              readonly
              v-model="scholarship.courseName"
              prepend-inner-icon="school"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <FundingAgencySelect
              :agencyName="scholarship.agencyName"
              v-on:send-agency="sendAgency"
            ></FundingAgencySelect>
          </v-col>
          <v-col cols="12" class="text-center">
            <ScholarshipQuotaSelect
              :agency="agency"
              :course="scholarship.courseName"
              :quotaId="scholarship.scholarshipQuotaId"
              v-on:send-quota="sendQuota"
            ></ScholarshipQuotaSelect>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-menu
              v-model="menuBegin"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  label="Data de início"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="beginDate"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="postAsyncAvailableResources()"
                  :disabled="disabled"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="br"
                type="month"
                v-model="beginDate"
                @input="menuBegin = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-menu
              v-model="menuEnd"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  outlined
                  dense
                  label="Data de término"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="br"
                type="month"
                v-model="endDate"
                @input="menuEnd = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-textarea
              v-model="scholarship.annotation"
              label="Observação"
              placeholder="Digite uma observação..."
              outlined
              @keypress.enter="asyncPostScholarship()"
              :rules="[rules.length]"
              counter="2000"
              :disabled="disabled"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-linear
              indeterminate
              color="green"
              v-show="disabled"
            ></v-progress-linear>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="red"
              :disabled="disabled"
              @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="green"
              :disabled="disabled"
              @click="putAsyncScholarship()"
              >Atualizar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatText } from "@/utils/formatField";
import FundingAgencySelect from "@/components/admin/funding_agency/components/FundingAgencySelect";
import ScholarshipQuotaSelect from "@/components/admin/scholarship_quota/components/ScholarshipQuotaSelect";

export default {
  name: "ScholarshipUpdate",
  props: ["item"],
  components: { FundingAgencySelect, ScholarshipQuotaSelect },
  data() {
    return {
      disabled: false,
      scholarship: {},
      quotas: [],
      quota: {},
      value: null,
      course: null,
      agency: {},
      menuBegin: false,
      menuEnd: false,
      endDate: this.item.end.substr(0, 7),
      beginDate: this.item.begin.substr(0, 7),
      rules: {
        length: (v) =>
          formatText.minor2000(v) ||
          "A observação não deve exceder 2000 caracteres!",
      },
    };
  },

  created() {
    if (this.fundingAgencies.length == 0) this.getAsyncFundingAgencies();
    this.asyncGetScholarship(this.item.id);
    this.agency = this.fundingAgencies.find((e) => e.name === this.item.agency);
  },

  computed: {
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("scholarship", [
      "ActionGetScholarshipsById",
      "ActionPutScholarship",
    ]),
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),
    ...mapActions("scholarshipQuota", [
      "ActionGetScholarshipQuotaByFundingAgency",
    ]),

    async asyncGetScholarship(id) {
      try {
        this.disabled - true;
        let res = await this.ActionGetScholarshipsById(id);
        this.scholarship = res.body;
        this.beginDate = this.scholarship.begin.substr(0, 7);
        this.endDate = this.scholarship.end.substr(0, 7);
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    async getAsyncScholarshipQuotaByFundingAgency(id) {
      try {
        this.disabled = true;
        const res = await this.ActionGetScholarshipQuotaByFundingAgency(id);

        this.quotas = res.body.filter(
          (e) => e.active === true && e.course === this.item.course
        );
        this.quota = this.quotas.find(
          (e) => e.id === this.scholarship.scholarshipQuotaId
        );
        this.disabled = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    async putAsyncScholarship() {
      if (this.beginDate < this.endDate || this.endDate == null) {
        try {
          this.disabled = true;
          let body = {
            id: this.scholarship.id,
            begin: this.configData(this.beginDate, true),
            end: this.configData(this.endDate, false) || null,
            academicLifeId: this.scholarship.academicLifeId,
            scholarshipQuotaId: this.quota.id,
            annotation: this.scholarship.annotation == undefined ? null : this.scholarship.annotation,
          };          
          await this.ActionPutScholarship(body);
          this.closeDialog();
        } catch (e) {
          console.error("Erro ao atualizar bolsa de estudos!", e);
        } finally {
          this.disabled = false;
        }
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    sendAgency(newValue) {
      this.agency = newValue;
    },

    sendQuota(newValue) {
      //console.log('quota', newValue);
      this.quota = newValue;
    },

    configData(date, isBeginDate) {
      let d = new Date(date);
      let dataInicio = new Date(d.valueOf() - d.getTimezoneOffset() * 60000);
      let dataFim = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);

      if (isBeginDate)
        return new Date(dataInicio.getFullYear(), dataInicio.getMonth() + 1, 1);
      else {
        if (date == null) return null;
        else return new Date(dataFim.getFullYear(), dataFim.getMonth() + 1, 0);
      }
    },

  },

  watch: {
    item(newItem) {
      if (newItem != null && newItem.id != null) {
        this.asyncGetScholarship(newItem.id);
        this.agency = this.fundingAgencies.find(
          (e) => e.name === newItem.agency
        );
        this.getAsyncScholarshipQuotaByFundingAgency(this.agency.id);
        this.quota = {};
      }
    },

    async agency(newValue) {
      this.quotas = [];
      this.quota = { id: 0, value: "", course: "" };
      if (newValue != null && newValue.id != null) {
        this.getAsyncScholarshipQuotaByFundingAgency(newValue.id);
      }
    },
  },
};
</script>

<style>
</style>