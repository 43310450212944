<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row wrap>
          <v-col cols="12" class="text-center">
            <h5 class="title">Trancamentos</h5>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col cols="12" class="text-center"
            ><h4>{{ item.student }}</h4></v-col
          >
        </v-row>
        <v-row wrap v-if="item.courseBreaks.length > 0">

          <v-col
            cols="12"
            class="text-left"
            v-for="b in item.courseBreaks"
            :key="b.id"
          >
            <div>Status: {{ b.status }}</div>
            <div>{{ b.description }}</div>
            <div>Início: {{ fDate(b.startDate) }}</div>
            <div>Término: {{ fDate(b.endDate) }}</div>
          <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row wrap v-else>
           <v-col
            cols="12"
            class="text-center"
            >
            <div>Aluno não possui trancamentos registrados.</div>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row wrap>
          <v-col cols="12" class="text-center">
            <v-btn outlined color="green" @click="closeDialog()">OK</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { formatDate } from "@/utils/formatDate";

export default {
  name: "ScholarshipBreaksInfo",
  props: ["item"],

  data() {
    return {};
  },

  created() {},

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },

    fDate(date) {
      return formatDate.dmy(date);
    }
  },
};
</script>

<style>
</style>