<template>
  <v-select
    dense
    outlined
    return-object
    item-text="name"
    v-model="agency"
    :items="fundingAgencies"
    :disabled="disabled"
    :label="message"
    prepend-inner-icon="business"
    no-data-text="Nenhuma agência de fomento"
  ></v-select>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "FundingAgencySelect",
  props: ["agencyName"],
  data() {
    return {
      agency: {},
      disabled: false,
      message: "Agências de Fomento",
    };
  },

  created() {
    if (this.fundingAgencies.length == 0) this.getAsyncAgencies();
    this.agency = this.fundingAgencies.find((e) => e.name == this.agencyName);
    this.sendSelectAgency();
  },

  computed: {
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),

    async getAsyncAgencies() {
      try {
        this.disabled = false;
        await this.ActionGetFundingAgencies();
        this.agency = this.fundingAgencies.find(
          (e) => e.name == this.agencyName
        );
        this.sendSelectAgency();
      } catch (e) {
        console.error("Erro ao buscar agências de fomento", e);
        this.message = "Erro ao buscar agências de fomento!";
      } finally {
        this.disabled = false;
      }
    },

    sendSelectAgency() {
      this.$emit("send-agency", this.agency);
    },
  },

  watch: {
    agencyName() {
      this.agency = this.fundingAgencies.find((e) => e.name == this.agencyName);
      this.sendSelectAgency();
    },

    agency() {
      this.sendSelectAgency();
    },
  },
};
</script>

<style>
</style>