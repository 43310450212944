<template>
  <v-container fluid>
    <v-flex>
      <v-row>
        <v-col cols="6" class="text-left">
          <h3>Bolsas de Estudos</h3>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            outlined
            x-small
            color="green"
            @click="openInsertDialog()"
            :disabled="agencies.length > 0"
            ><v-icon small>add</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-flex>
    <v-flex mt-5>
      <v-card color="grey lighten-4">
        <v-card-title>
          <v-flex xs12 md3>
            <v-text-field
              dense
              v-model="search"
              append-icon="search"
              placeholder="Buscar aluno"
            ></v-text-field>
          </v-flex>
        </v-card-title>
        <v-data-table
          :items="items"
          :search="search"
          :headers="headers"
          :loading="loading"
          :items-per-page="25"
          
          loading-text="Buscando bolsas de estudo"
          no-data-text="Não foram encontradas bolsas de estudo"
        >
          <template v-slot:item="props">
            <tr>
              <td class="text-xs-left">{{ props.item.agency }}</td>
              <td class="text-xs-left">{{ props.item.quota }}</td>
              <td class="text-xs-left">{{ props.item.value }}</td>
              <td class="text-xs-left">{{ props.item.course }}</td>
              <td class="text-xs-left">{{ props.item.student }}</td>
              <td class="text-xs-left">{{ dmy(props.item.begin) }}</td>
              <td class="text-xs-left">{{ dmy(props.item.end) }}</td>
              <td class="text-xs-left">
                <v-btn
                  x-small
                  outlined
                  color="black"
                  @click="openCourseBreaks(props.item)"
                  :disabled="props.item.courseBreaks.length == 0"
                  >{{ props.item.courseBreaks.length }}</v-btn
                >
              </td>
              <td class="text-xs-center">
                <v-btn
                  x-small
                  text
                  color="black"
                  @click="openInfoDialog(props.item)"
                  ><v-icon>visibility</v-icon></v-btn
                >
              </td>
              <td class="text-xs-left">
                <v-btn
                  x-small
                  text
                  color="green"
                  :disabled="!props.item.active"
                  @click="openDeactiveDialog(props.item)"
                  ><v-icon>flag</v-icon></v-btn
                >
              </td>
              <td class="text-xs-left">
                <v-btn
                  x-small
                  outlined
                  color="orange"
                  :disabled="!props.item.active"
                  @click="openUpdateDialog(props.item)"
                  ><v-icon small>edit</v-icon></v-btn
                >
              </td>
              <td class="text-xs-left">
                <v-btn
                  x-small
                  outlined
                  color="red"
                  @click="openDelDialog(props.item)"
                  ><v-icon small>delete</v-icon></v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>

    <v-dialog v-model="insertDialog" max-width="700">
      <ScholarshipInsert
        :scholarship="item"
        v-on:close-dialog="closeInsertDialog()"
      ></ScholarshipInsert>
    </v-dialog>

    <v-dialog v-model="infoCourseBreaks" max-width="700">
      <ScholarshipBreaksInfo
        :item="breaks"
        v-on:close-dialog="closeCourseBreaks()"
      ></ScholarshipBreaksInfo>
    </v-dialog>

    <v-dialog v-model="deactivateDialog" max-width="600">
      <ScholarshipDeactivate
        :scholarship="item"
        v-on:close-dialog="closeDeactiveDialog()"
      ></ScholarshipDeactivate>
    </v-dialog>

    <v-dialog v-model="delDialog" max-width="600">
      <ScholarshipDelete
        :scholarship="item"
        v-on:close-dialog="closeDelDialog()"
      ></ScholarshipDelete>
    </v-dialog>

    <v-dialog v-model="updateDialog" max-width="700">
      <ScholarshipUpdate
        :item="item"
        v-on:close-dialog="closeUpdateDialog()"
      ></ScholarshipUpdate>
    </v-dialog>

    <v-dialog v-model="infoDialog" max-width="700">
      <ScholarshipInfo
        :item="item"
        v-on:close-dialog="closeInfoDialog()"
      ></ScholarshipInfo>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";
import ScholarshipInfo from "./ScholarshipInfo";
import ScholarshipDelete from "./ScholarshipDelete";
import ScholarshipInsert from "./ScholarshipInsert";
import ScholarshipUpdate from "./ScholarshipUpdate";
import ScholarshipBreaksInfo from "./ScholarshipBreaksInfo";
import ScholarshipDeactivate from "./ScholarshipDeactivate";

export default {
  name: "Scholarship",
  props: [],
  components: {
    ScholarshipInfo,
    ScholarshipDelete,
    ScholarshipInsert,
    ScholarshipUpdate,
    ScholarshipDeactivate,
    ScholarshipBreaksInfo,
  },
  data() {
    return {
      headers: [
        { sortable: true, text: "Agência", value: "agency", align: "left" },
        { sortable: true, text: "Cota", value: "quota", align: "left" },
        { sortable: true, text: "Valor R$", value: "value", align: "left" },
        { sortable: true, text: "Curso", value: "course", align: "left" },
        { sortable: true, text: "Aluno", value: "student", align: "left" },
        { sortable: true, text: "Início", value: "begin", align: "left" },
        { sortable: true, text: "Término", value: "end", align: "left" },
        {
          sortable: true,
          text: "Trancamentos",
          value: "courseBreaks",
          align: "left",
        },
        { sortable: false, text: "Visualizar", value: "end", align: "left" },
        { sortable: true, text: "Ativa", value: "active", align: "left" },
        { sortable: false, text: "Editar", value: "edit", align: "left" },
        { sortable: false, text: "Excluir", value: "remove", align: "left" },
      ],
      items: [],
      item: {},
      breaks: [],
      search: "",
      agencies: [],
      loading: false,
      delDialog: false,
      infoDialog: false,
      insertDialog: false,
      updateDialog: false,
      deactivateDialog: false,
      infoCourseBreaks: false,
    };
  },

  created() {
    if (this.items.length == 0 && this.scholarships) {
      this.getAsyncScholarships();
    } else {
      this.insertItems(this.scholarships);
    }

    if (this.agencies.length == 0) {
      this.getAsyncFundingAgencies();
    }
  },

  computed: {
    ...mapState("scholarship", ["scholarships"]),
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("scholarship", ["ActionGetScholarships"]),
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),

    dmy(date){
      return formatDate.dmy(date);
    },

    async getAsyncScholarships() {
      try {
        this.loading = true;
        await this.ActionGetScholarships();
      } catch (e) {
        console.error("Erro ao buscas as bolas de estudo", e);
      } finally {
        this.loading = false;
      }
    },

    async getAsyncFundingAgencies() {
      try {
        await this.ActionGetFundingAgencies();
      } catch (err) {
        console.log(err);
      }
    },

    insertItems(scholarships) {
      this.items = [];
      scholarships.map((e) => {
        this.items.push({
          id: e.id,
          end: e.end,
          begin:e.begin,
          value: e.value,
          agency: e.agencyName,
          course: e.courseName,
          student: e.studentName,
          annotation: e.annotation,
          quota: e.scholarshipQuotaId,
          courseBreaks: e.courseBreaks,
          active: e.active ? true : false
        });
      });
    },

    customSort(items, index) {
      //console.log('items', items);
      // console.log('index', index);
      //console.log('isDesc', isDesc);
      items.sort((a, b) => {
        return a[index] < b[index] ? -1 : 1;
      });
      return items;
    },

    openUpdateDialog(item) {
      this.updateDialog = true;
      this.item = item;
    },
    closeUpdateDialog() {
      this.updateDialog = false;
      this.item = {};
    },
    openDeactiveDialog(item) {
      this.deactivateDialog = true;
      this.item = item;
    },
    closeDeactiveDialog() {
      this.deactivateDialog = false;
      this.item = {};
    },
    openDelDialog(item) {
      this.delDialog = true;
      this.item = item;
    },
    closeDelDialog() {
      this.delDialog = false;
      this.item = {};
    },
    openInsertDialog() {
      this.insertDialog = true;
    },
    closeInsertDialog() {
      this.insertDialog = false;
    },
    openInfoDialog(item) {
      this.item = item;
      this.infoDialog = true;
    },
    closeInfoDialog() {
      this.infoDialog = false;
    },
    openCourseBreaks(item) {
      this.infoCourseBreaks = true;
      this.breaks = item;
    },
    closeCourseBreaks() {
      this.infoCourseBreaks = false;
    },
  },

  watch: {
    scholarships(newValue) {
      if (newValue) this.insertItems(newValue);
    },
    agencies(newValue) {
      if (newValue) this.agencies = newValue;
    },
  },
};
</script>

<style>
</style>