<template>
  <div>
    <v-row>
      <v-col cols="4" class="text-center">
        <v-select
          dense
          outlined
          return-object
          item-text="id"
          item-value="id"
          v-model="quota"
          :items="quotas"
          :disabled="disabled"
          :label="message"
          prepend-inner-icon="school"
          no-data-text="Cotas inexistentes"
        ></v-select>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-text-field
          outlined
          dense
          label="Valor R$"
          readonly
          disabled
          prepend-inner-icon="school"
          v-model="value"
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-text-field
          outlined
          dense
          label="Curso"
          readonly
          disabled
          prepend-inner-icon="school"
          v-model="courseName"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ScholarshipQuotaSelect",
  props: ["agency", "course", "quotaId"],
  data() {
    return {
      value: "",
      courseName: "",
      quota: {},
      quotas: [],
      disabled: false,
      message: "Cota",
    };
  },

  created() {
    this.getAsyncQuotas();
  },

  methods: {
    ...mapActions("scholarshipQuota", [
      "ActionGetScholarshipQuotaByFundingAgency",
    ]),

    async getAsyncQuotas() {
     
      this.quotas = [];
      this.quota = {};
      this.value = "";
      this.courseName = "";
      this.disabled = true;
      try {
        let res = await this.ActionGetScholarshipQuotaByFundingAgency(this.agency.id);
        this.quota = res.body.find(e => e.id === this.quotaId);
        this.quotas = res.body.filter((e) => e.course === this.course && e.allocated == false);
        if(this.quota != undefined)
          this.quotas.push(this.quota);
        
        if (this.quota == undefined) {
          this.courseName = "";
          this.value = "";
        } else {
          this.courseName = this.quota.course;
          this.value = this.quota.value;
        }
      } catch (e) {
        console.error("Erro ao buscar cotas para a agência!", e);
      } finally {
        this.disabled = false;
      }
    },

    sendSelectQuota() {
      this.$emit("send-quota", this.quota);
    },
  },

  watch: {
    agency() {
      this.getAsyncQuotas();
      this.sendSelectQuota();
    },

    quota(newValue) {
      if (newValue != undefined) {
        this.value = newValue.value;
        this.courseName = newValue.course;
        this.sendSelectQuota();
      }
    },

    course() {
      this.getAsyncQuotas();
      this.sendSelectQuota();
    },

    quotaId() {
      this.getAsyncQuotas();
      this.sendSelectQuota();
    },
  },
};
</script>

<style>
</style>